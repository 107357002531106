import React from "react";
import Layout from "@components/Layout";
import Profil from "@views/Profil";

export default function Index() {
  return (
    <Layout noFooter>
      <Profil />
    </Layout>
  );
}

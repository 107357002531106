import React from "react";
import Content from "./Sections/Content";
import HeroHeader from "./Sections/HeroHeader";

export default function Profil() {
  return (
    <div>
      <HeroHeader />
      <Content />
    </div>
  );
}

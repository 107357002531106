import React from "react";
import { Card, Container } from "react-bootstrap";

export default function Content() {
  return (
    <section id="profil-content">
      <Container>
        <Card className="border-0 shadow">
          <Card.Body>
            <p>
              MobilAmbulance.id adalah website yang merupakan Jasa Pembuatan
              Mobil Ambulance, Pusat Karoseri Ambulance Tangerang, Harga
              Karoseri Mobil Ambulance Bekasi, Biaya Pembuatan Ambulance,
              Workshop Pembuatan Ambulance Jakarta dan Modifikasi Ambulance
              Jambi, Modifikasi Mobil Ambulance Surabaya, Pembuatan Ambulance
              Jogjakarta / Jogja, Bengkel Modifikasi Ambulance, Jasa Pembuatan
              Ambulance Jasamarga, Ambulance Daihatsu (Ambulance Gran Max &
              Ambulance Luxio), Ambulance Suzuki (Ambulance APV), Pembuatan Unit
              Ambulance Toyota (Ambulance Hiace), Ambulance Jenazah, Mobil
              Jenazah, Ambulance 4X4, Rental Ambulance & Sewa Ambulance
              Spesialis Jalan tol Jasa Marga, Industri Alat Medis / Alat
              kesehatan (Alkes) dan Keranda Jenazah Terlengkap dengan
              mengutamakan Pelayanan untuk kepuasan mitra Di Provinsi dan Di
              daerah-daerah seperti Sumatera, Jawa, Kalimantan, Sulawesi, Papua
              dan DKI Jakarta, Serta Bogor, Depok, Tangerang Selatan, Bekasi,
              Serang Banten, Bandung, Sukabumi, Cirebon, Tasikmalaya, Surabaya,
              Semarang, Malang, Solo, Yogyakarta, Banda Aceh, Medan, Jambi,
              Padang, Palembang, Bandar Lampung, Manado, Pekanbaru, Samarinda,
              Pontianak, Banjarmasin, Kota Batam, Makassar, Denpasar,
              Balikpapan, Surakarta, Kupang, Kediri, Bengkulu, Ambon, Bali,
              Sorong, Jayapura, Dari Bagian Utara, Timur, Barat dan Selatan.
            </p>

            <p>
              Secara Legalitas Berdiri Sejak 2002 Dengan Usaha Awal Distributor
              Alat Kesehatan Dan Kini Berkembang Membuat Bengkel Untuk Pembuatan
              Alat Transfortasi Medis.
            </p>

            <p>
              Saat ini kami mempunyai Bengkel rekanan yang berlokasi Wilayah
              Jakarta, Bandung, Bekasi dan Depok. Dengan jangkauan pelayanan
              yang di dukung ekspedisi dan cargo yang memberikan service cepat
              dan tepat kepada pelanggan di seluruh Indonesia.
            </p>

            <p>
              Karoseri Ambulance
              <br />
              berkomitmen membantu Anda dalam segala bentuk dan ukuran Mobil
              maupun berbagai merek mobil yang biasa digunakan untuk modifikasi
              ambulance seperti Toyota, Suzuki, Nissan, Daihatsu, Isuzu ,
              Mitsubishi ,dll . Tanpa menganggu waktu Anda yang begitu berharga
              untuk cara pemesanan maupun cara pengiriman untuk semua produk
              kami. Apalagi dengan kondisi lalu lintas kota besar yang sering
              mengalami macet. Dan kondisi geografis Indonesia terdiri dari
              ribuan kepulauan kami hadir untuk mempermudah Anda dan Bisa
              Bekerjasama menjadi Mitra Rekanan yang sangat baik.
            </p>

            <h4 className="text-secondary">VISI</h4>
            <ol>
              <li>
                Menjadi Perusaha Perakitan Mobil Karoseri Dan Transportasi Medis
                Yang Unggul, Profesional Berstandar Nasional.
              </li>
            </ol>

            <h4 className="text-secondary">MISI</h4>
            <ol>
              <li>Menerapkan Manajemen Perusahaan Yang Efisien Dan Efektif.</li>
              <li>
                Memberikan Kepuasan Mitra Dan Pelanggan Secara Berkesinambungan
                (Service Excellent).
              </li>
              <li>
                Mengembangkan Sumber Daya Manusia Yang Handal, Tumbuh, Dan
                Profesional Serta Meningkatkan Kesejahteraan Karyawan.
              </li>
            </ol>

            <p>
              Mengoptimalkan Sinergi Dengan Pihak Swasta Dan Pemerintah.
              <br />
              Kami bertekad agar MobilAmbulance.id dapat bersinergi dan
              bekerjasama kepada semua steak holder atau masyarakat yang
              memerlukan kami sehingga KaroseriAmbulance.com bukan perusahaan
              mengedepankan profit semata tetapi lebih mengutamakan profesional.
            </p>

            <p>
              Agar Indonesia Berdaya. Doakan kami agar Perusahaan ini selalu
              amanah dan professional dalam bekerja dan berkarya untuk
              Indonesia.
            </p>

            <p>Terima Kasih Atas Kepercayaan Yang anda Berikan Kepada Kami</p>

            <p>Hormat Kami,</p>
            <br />
            <br />
            <h4 className="text-primary">Manajemen<br />MobilAmbulance.Id</h4>
          </Card.Body>
        </Card>
      </Container>
    </section>
  );
}

import React from "react";

export default function HeroHeader() {
  return (
    <section id="hero" className="profile">
      <div className="hero-header">
        <div className="image">
          <img src="/images/profil/hero-image.png" alt="HERO IMAGE" />
        </div>
      </div>
    </section>
  );
}
